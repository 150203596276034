import { call, all, select, put, take, fork, delay } from 'redux-saga/effects';

import { Category, ProductSortField, QueryProductSearchArgs, SortDirection } from 'types/schema';
import { addFavorite, getGroupCategoriesData, getProducts, removeFavorite, setProductNote } from 'utils/apollo';
import { selectSections } from './selectors';
import {
  gyomuSuperFavorite,
  gyomuSuperProduct,
  gyomuSuperSetProductNote,
  setGyomuCategories,
  initGyomuPage,
} from './actions';
import ActionTypes from './constants';
import { message } from 'antd';
import translations from 'translations';
import utilMessages from 'utils/messages';
import { updateNoteToCard } from 'containers/MainLayout/actions';
import { selectCategories } from 'containers/MainLayout/selectors';
import { ISection } from './types';

interface ISectionParams {
  categoryName: string;
  page: number;
  tags?: string[];
}

export const isMobile = window.document.body.clientWidth < 768;

function* getSectionData(section: ISectionParams) {
  const params: QueryProductSearchArgs = {
    sort: [{ field: ProductSortField.Name, order: SortDirection.Asc }],
    filter: {
      tags: section?.tags || ['GYOMU_SUPER'],
      categoryName: section.categoryName === 'NewProducts' ? undefined : section.categoryName,
    },
    pagination: { page: section.page - 1 || 0, size: isMobile ? 6 : 10 },
  };
  const response = yield call(getProducts, params);

  if (response.errors) {
    yield put(gyomuSuperProduct.failure({ [section.categoryName]: { data: [], error: true } }));
  } else {
    const sections = yield select(selectSections());
    const currentSection = sections?.[section?.categoryName] || {};
    yield put(
      gyomuSuperProduct.success({
        [section.categoryName]: {
          data: Object.keys(currentSection).length ? [...currentSection?.data, ...response.data] : response.data,
          loading: false,
          banner: '',
          error: false,
          totalPage: response.totalPage,
          page: Number(section.page) + 1,
        },
      }),
    );
  }
}

function* loadAllSectionData() {
  yield put(initGyomuPage());
  const lang = window.localStorage.getItem('lang') || 'vi';
  const gyomuSuperSubCategories = yield call(getGroupCategoriesData, { tagsList: ['GYOMU_SUPER'] });
  if (gyomuSuperSubCategories?.length) {
    const gyomuSuperSubCategoriesIdsArr = gyomuSuperSubCategories.map((item) => item.cateId);
    let categoriesData = [] as Category[];
    while (!categoriesData?.length) {
      const categories = yield select(selectCategories());
      if (categories?.length) {
        categoriesData = categories;
      }
      yield delay(10);
    }
    let sections = {} as ISection;

    const gyomuSuperCategories = categoriesData.reduce((acc, item) => {
      const hasCategory = item?.children?.some((it) => gyomuSuperSubCategoriesIdsArr.includes(it.id));
      if (hasCategory) {
        sections[item.name] = {
          loading: true,
          data: [],
        };
        return [...acc, item];
      }
      return acc;
    }, []);

    gyomuSuperCategories.unshift({
      id: 0,
      name: 'NewProducts',
      nameLocal: lang === 'vi' ? 'Sản phẩm mới' : 'New products',
      children: [],
    });

    sections = {
      newProducts: {
        data: [],
        loading: true,
      },
      ...sections,
    };

    yield put(setGyomuCategories(gyomuSuperCategories));
    yield put(gyomuSuperProduct.request(sections));
    yield all(
      gyomuSuperCategories.map((item) => {
        if (item.name === 'NewProducts') {
          return call(getSectionData, { categoryName: item.name, page: 1, tags: ['NEW_PRODUCTS', 'GYOMU_SUPER'] });
        }
        return call(getSectionData, { categoryName: item.name, page: 1 });
      }),
    );
  }
}

function* loadMoreDataInSection() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_MORE_SECTION_DATA);
    const sections = yield select(selectSections());
    sections[payload.categoryName] = {
      ...sections[payload.categoryName],
      loading: true,
    };
    yield put(gyomuSuperProduct.request(sections));
    yield call(getSectionData, payload);
  }
}
function* gyomuSuperFavoriteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.GYOMU_BRAND_FAVORITE);
    yield put(gyomuSuperFavorite.request());

    const {
      product: { isFavorite, id },
      index,
      section,
    } = payload;

    const response = yield call(isFavorite ? removeFavorite : addFavorite, { id: id });
    if (response.errors) {
      yield put(gyomuSuperFavorite.failure({ index: index, section: section }));
    } else {
      yield put(gyomuSuperFavorite.success({ index: index, section: section, isFavorite: !isFavorite }));
    }
  }
}

function* gyomuSuperSetProductNoteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.GYOMU_BRAND_SET_PRODUCT_NOTE);
    yield put(gyomuSuperSetProductNote.request());

    const { id, note, index, section } = payload;
    const response = yield call(setProductNote, { input: { productId: id, note: note } });
    if (response.errors) {
      yield put(gyomuSuperSetProductNote.failure(response.errors));
      message.error(translations(utilMessages[payload.note ? 'addItemNoteFailed' : 'deleteItemNoteFailed']));
    } else {
      yield put(gyomuSuperSetProductNote.success({ note: note, index: index, section: section }));
      yield put(updateNoteToCard(response.product));
      message.success(translations(utilMessages[payload.note ? 'addItemNoteSuccess' : 'deleteItemNoteSuccess']));
    }
  }
}

export default function* saga() {
  yield call(loadAllSectionData);
  yield fork(gyomuSuperFavoriteFlow);
  yield fork(gyomuSuperSetProductNoteFlow);
  yield fork(loadMoreDataInSection);
}
