import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectGyomuSuperPageDomain = (state: ApplicationRootState) => state.gyomuSuperPage || initialState;

const selectGyomuSuperPage = () => createSelector(selectGyomuSuperPageDomain, (substate) => substate);

const selectLoading = () => createSelector(selectGyomuSuperPageDomain, (substate) => substate.loading);
const selectSections = () => createSelector(selectGyomuSuperPageDomain, (substate) => substate.sections);
const selectNoteItemLoading = () => createSelector(selectGyomuSuperPageDomain, (substate) => substate.loadingNote);
const selectGyomuCategories = () => createSelector(selectGyomuSuperPageDomain, (substate) => substate.gyomuCategories);


export default selectGyomuSuperPage;
export { selectGyomuSuperPageDomain, selectLoading, selectSections, selectNoteItemLoading, selectGyomuCategories };
