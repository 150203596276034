import { action, createAsyncAction } from 'typesafe-actions';
import ActionTypes from './constants';

export const gyomuSuperProduct = createAsyncAction(
  ActionTypes.GYOMU_BRANDS_REQUEST,
  ActionTypes.GYOMU_BRANDS_SUCCESS,
  ActionTypes.GYOMU_BRANDS_FAILURE,
)<any, {}, { [key: string]: { data: []; error: boolean } }>();

export const setGyomuSuperFavorite = (data: any) => action(ActionTypes.GYOMU_BRAND_FAVORITE, data);
export const gyomuSuperFavorite = createAsyncAction(
  ActionTypes.GYOMU_BRAND_FAVORITE_REQUEST,
  ActionTypes.GYOMU_BRAND_FAVORITE_SUCCESS,
  ActionTypes.GYOMU_BRAND_FAVORITE_FAILURE,
)<void, {}, {}>();

export const setGyomuSuperProductNote = (data: any) => action(ActionTypes.GYOMU_BRAND_SET_PRODUCT_NOTE, data);
export const gyomuSuperSetProductNote = createAsyncAction(
  ActionTypes.GYOMU_BRAND_SET_PRODUCT_NOTE_REQUEST,
  ActionTypes.GYOMU_BRAND_SET_PRODUCT_NOTE_SUCCESS,
  ActionTypes.GYOMU_BRAND_SET_PRODUCT_NOTE_FAILURE,
)<void, {}, Error>();
export const getMoreSectionData = (data: any) => action(ActionTypes.GET_MORE_SECTION_DATA, data);
export const setGyomuCategories = (data: any) => action(ActionTypes.SET_GYOMU_SUPER_CATEGORIES, data);
export const initGyomuPage = () => action(ActionTypes.GYOMU_BRANDS_INIT);

