import React, { useRef, useState, useEffect } from 'react';
import styled from 'styles/styled-components';
import * as images from '../images';

import media from 'utils/mediaStyle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Viewport } from 'components/ViewportProvider';

import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-top: 36px;
  margin-bottom: 48px;
  overflow-x: auto;
  padding: 10px 0;
  max-width: 1200px;
  width: 100%;
  ${media.lg`
    gap: 34px;
    overflow-x: unset;
    padding: unset;
  `}
  @media (max-width: 1280px) {
    max-width: 997px;
    margin: 36px auto 48px;
  }
`;
const CategoryItem = styled.div``;

const CategoryItemName = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  transition: transform 0.3s;
  ${media.md`
    font-size: 18px;
    `}
`;

const CategoryItemImage = styled.img`
  width: 50px;
  height: 50px;
  ${media.md`
    width: 90px;
    height: 90px;
    `}
`;

const CategoryItemImageWrapper = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 1px solid #eaeaeb;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s;
  margin-bottom: 12px;
  ${({ backgroundHover }) => `
      background: ${backgroundHover};
    `}
  ${media.lg`
    background: transparent;
    `}
  ${media.md`
    width: 120px;
    height: 120px;
    `}
  &:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
    ${({ backgroundHover }) => `
      background: ${backgroundHover};
    `}
  }
  &:hover ~ ${CategoryItemName} {
    transform: translateY(8px);
  }
`;
const SwiperElement = styled(Swiper)`
  max-width: 1136px;
  margin: 0px !important;
  padding: 10px 5px !important;
  .swiper-button-prev.swiper-button-disabled {
    display: none !important;
  }
  .swiper-button-prev.swiper-button-disabled {
    display: none !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    background-color: rgba(0, 0, 0, 0.64) !important;
    &:after {
      font-size: 16px !important;
      color: #ffffff !important;
    }
    &.swiper-button-disabled {
      display: none !important;
    }
  }
`;
const SwiperSlideElement = styled(SwiperSlide)`
  width: fit-content !important;
`;

const NavigationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -55px;
  top: 50%;
  transform: translateY(-75%);
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-color: rgba(233, 233, 233, 0.4) !important;
  cursor: pointer;
  z-index: 100;
  @media (max-width: 1280px) {
    ${({ nextBtn }) =>
      nextBtn &&
      `
      right: 0px;
    `}
    ${({ prevBtn }) =>
      prevBtn &&
      `
      left: 0px !important;
    `}
    background-color: rgba(233, 233, 233, 0.8) !important;
  }
`;
const Arrow = styled.img`
  width: 24px !important;
  height: 24px !important;
`;

interface IProps {
  categories: any;
}
const backgroundColor = {
  NewProducts: '#C6EDFB',
  Veggies: '#D3F1A7',
  Meat: '#FEDEC8',
  Beverage: '#F8E6A0',
  'Dry Food': '#FDD0EC',
  Seasoning: '#DFD8FD',
  Seafood: '#DFD8FD',
  Dairy: '#ffffff',
  Confection: '#FEDEC8',
  Alcohol: '#C6EDFB',
  'Non-food': '#D3F1A7',
};

const CategoriesSection = ({ categories }: IProps) => {
  const [showNextBtn, setShowNextBtn] = useState(true);
  const [showPreBtn, setShowPreBtn] = useState(false);

  const onScrollToSection = (sectionName: string) => {
    const section = document.getElementById(sectionName);
    if (section) {
      const sectionTop = section.offsetTop - 20;
      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth',
      });
    }
  };
  const swiperRef: any = useRef();
  const formatCategoryName = (categoryName) => {
    const categoryNameSplited = categoryName.split(/\-|\s/);
    if (categoryNameSplited.length === 1) return categoryName;
    return categoryNameSplited.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join('');
  };
  useEffect(() => {
    setTimeout(() => {
      setShowNextBtn(true);
      setShowPreBtn(false);
    }, 0);
  }, []);
  return (
    <>
      <Viewport.Consumer>
        {({ width }) => (
          <>
            {width > 1023 ? (
              <Container>
                <SwiperElement
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  slidesPerView={'auto'}
                  spaceBetween={24.5}
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                  onReachEnd={() => {
                    setShowPreBtn(true);
                    setShowNextBtn(false);
                  }}
                  onReachBeginning={() => {
                    setShowPreBtn(false);
                    setShowNextBtn(true);
                  }}
                >
                  {categories.map((item, index) => (
                    <SwiperSlideElement key={index}>
                      <CategoryItem onClick={() => onScrollToSection(item.name)}>
                        <CategoryItemImageWrapper backgroundHover={backgroundColor[item.name]}>
                          <CategoryItemImage src={images?.[formatCategoryName(item.name)]} />
                        </CategoryItemImageWrapper>
                        <CategoryItemName>{item.nameLocal}</CategoryItemName>
                      </CategoryItem>
                    </SwiperSlideElement>
                  ))}
                </SwiperElement>
                {showNextBtn && (
                  <NavigationButton nextBtn={true} onClick={() => swiperRef.current.slideTo(categories.length - 1)}>
                    <Arrow src={images.Arrow} />
                  </NavigationButton>
                )}
                {showPreBtn && (
                  <NavigationButton
                    prevBtn={true}
                    style={{ left: '-55px', right: 'unset' }}
                    onClick={() => swiperRef.current.slideTo(0)}
                  >
                    <Arrow src={images.Arrow} style={{ transform: 'rotate(180deg)' }} />
                  </NavigationButton>
                )}
              </Container>
            ) : (
              <Container>
                {categories.map((item, index) => (
                  <CategoryItem key={index} onClick={() => onScrollToSection(item.name)}>
                    <CategoryItemImageWrapper backgroundHover={backgroundColor[item.name]}>
                      <CategoryItemImage src={images?.[formatCategoryName(item.name)]} />
                    </CategoryItemImageWrapper>
                    <CategoryItemName>{item.nameLocal}</CategoryItemName>
                  </CategoryItem>
                ))}
              </Container>
            )}
          </>
        )}
      </Viewport.Consumer>
    </>
  );
};

export default CategoriesSection;
