import { ContainerActions, ContainerState } from './types';
import ActionTypes from './constants';

export const isMobile = window.document.body.clientWidth < 768;

export const initialState: ContainerState = {
  loading: true,
  sections: {},
  loadingNote: false,
  gyomuCategories: [],
};

function gyomuSuperReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.GYOMU_BRANDS_INIT:
      return { ...state, loading: true };
    case ActionTypes.GYOMU_BRANDS_REQUEST:
      return { ...state, sections: payload };

    case ActionTypes.GYOMU_BRANDS_SUCCESS:
      return { ...state, loading: false, sections: { ...state.sections, ...payload } };

    case ActionTypes.GYOMU_BRANDS_FAILURE: {
      const keys = Object.keys(payload);
      const sections = { ...state.sections };
      keys.forEach((key) => {
        if (!sections[key]) {
          sections[key] = {
            ...payload[key],
            loading: false,
          };
        }
      });
      return { ...state, loading: false, sections: sections };
    }
    case ActionTypes.GYOMU_BRAND_SET_PRODUCT_NOTE_REQUEST: {
      return { ...state, loadingNote: true };
    }

    case ActionTypes.GYOMU_BRAND_SET_PRODUCT_NOTE_SUCCESS: {
      const { note, index, section } = payload;
      const products = [...state.sections[section].data];
      products[index] = { ...products[index], note: note };
      return {
        ...state,
        sections: { ...state.sections, [section]: { data: products, error: false } },
        loadingNote: false,
      };
    }

    case ActionTypes.GYOMU_BRAND_SET_PRODUCT_NOTE_FAILURE: {
      return { ...state, loadingNote: false };
    }

    case ActionTypes.GYOMU_BRAND_FAVORITE_SUCCESS: {
      const { index, section, isFavorite } = payload;
      const products = [...state.sections[section].data];
      products[index] = { ...products[index], isFavorite: isFavorite };
      return {
        ...state,
        sections: { ...state.sections, [section]: { data: products, error: false } },
      };
    }
    case ActionTypes.SET_GYOMU_SUPER_CATEGORIES: {
      return {
        ...state,
        gyomuCategories: payload,
      };
    }
    default:
      return state;
  }
}

export default gyomuSuperReducer;
