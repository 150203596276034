enum ActionTypes {
  GYOMU_BRANDS_REQUEST = 'app/LandingPage/GyomuSuper/GYOMU_BRANDS_REQUEST',
  GYOMU_BRANDS_SUCCESS = 'app/LandingPage/GyomuSuper/GYOMU_BRANDS_SUCCESS',
  GYOMU_BRANDS_FAILURE = 'app/LandingPage/GyomuSuper/GYOMU_BRANDS_FAILURE',
  GYOMU_BRAND_FAVORITE = 'app/LandingPage/GyomuSuper/GYOMU_BRAND_FAVORITE',
  GYOMU_BRAND_FAVORITE_REQUEST = 'app/LandingPage/GyomuSuper/GYOMU_BRAND_FAVORITE_REQUEST',
  GYOMU_BRAND_FAVORITE_SUCCESS = 'app/LandingPage/GyomuSuper/GYOMU_BRAND_FAVORITE_SUCCESS',
  GYOMU_BRAND_FAVORITE_FAILURE = 'app/LandingPage/GyomuSuper/GYOMU_BRAND_FAVORITE_FAILURE',
  GYOMU_BRAND_SET_PRODUCT_NOTE = 'app/LandingPage/GyomuSuper/GYOMU_BRAND_SET_PRODUCT_NOTE',
  GYOMU_BRAND_SET_PRODUCT_NOTE_REQUEST = 'app/LandingPage/GyomuSuper/GYOMU_BRAND_SET_PRODUCT_NOTE_REQUEST',
  GYOMU_BRAND_SET_PRODUCT_NOTE_SUCCESS = 'app/LandingPage/GyomuSuper/GYOMU_BRAND_SET_PRODUCT_NOTE_SUCCESS',
  GYOMU_BRAND_SET_PRODUCT_NOTE_FAILURE = 'app/LandingPage/GyomuSuper/GYOMU_BRAND_SET_PRODUCT_NOTE_FAILURE',
  GET_MORE_SECTION_DATA = 'app/LandingPage/GyomuSuper/GET_MORE_SECTION_DATA',
  SET_GYOMU_SUPER_CATEGORIES = 'app/LandingPage/GyomuSuper/SET_GYOMU_SUPER_CATEGORIES',
  GYOMU_BRANDS_INIT='app/LandingPage/GyomuSuper/GYOMU_BRANDS_INIT'
}

export default ActionTypes;
