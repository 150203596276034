import { Button, Card, Skeleton } from 'antd';
import ModalNote from 'components/Checkout/ModalNote';
import MarketItem from 'components/MarketItem/MarketItem';
import { push } from 'connected-react-router';
import { IHomeFavoritePayload, IHomeProductNotePayload } from 'containers/HomePage/types';
import { selectAddedProducts, selectAddingProducts, selectUserPermissions } from 'containers/MainLayout/selectors';
import { AddedProduct, AddingProduct } from 'containers/MainLayout/types';
import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import { ApplicationRootState } from 'types';
import { Category, Product, TrackingAddToCartAddFromType, TrackingProductImpressionType } from 'types/schema';
import media from 'utils/mediaStyle';
import { getMoreSectionData, setGyomuSuperFavorite, setGyomuSuperProductNote } from '../actions';
import backgroundSection from '../images/section_background.png';
import messages from '../messages';
import { selectLoading, selectNoteItemLoading } from '../selectors';
import { ISectionData } from '../types';

const Container = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${media.lg`
    margin-bottom: 48px;
    gap: 24px;
  `};
`;

const List = styled('div')`
  padding: 1px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  ${media.lg`
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  `};
  @media (max-width: 1024px) and (min-width: 768px) {
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const Item = styled('div')`
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  ${media.md`
    width:100%;
    box-shadow: none;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled(Button)`
  height: 40px;
  padding: 12px;
  border-radius: 8px;
  color: #43a047;
  border-color: #43a047;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background: transparent;
`;

const SectionTitleWrapper = styled.div`
  position: relative;
`;

const SectionTitleBackground = styled.img`
  width: fit-content;
  @media (max-width: 767px) {
    width: 172px;
    height: 36px;
  }
`;
const SectionTitleText = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  ${media.md`
    font-size: 28px;
  `};
`;

const SkeletonImage = styled(Skeleton.Image)`
  width: 100% !important;
  & > div {
    width: 100% !important;
    height: 200px !important;
  }
`;

interface IOwnProps {
  sectionName: string;
  sectionData: ISectionData;
  showButtonViewAll?: boolean;
  category: Category;
}

interface IStateProps {
  loading: boolean;
  addedProducts: AddedProduct;
  addingProducts: AddingProduct;
  permissions: string[];
  noteItemLoading: boolean;
}
interface IDispatchProps {
  onToggleFavorite: (data: IHomeFavoritePayload) => void;
  onSetProductNote: (data: IHomeProductNotePayload) => void;
  gotoSignIn: () => void;
  loadMoreData: (data: any) => void;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

const Meta = Card.Meta;
const ProductSkeleton = () => (
  <List gutter={[16, 16]}>
    {[...Array(5)].map((_, idx) => (
      <div key={idx}>
        <Card style={{ width: '100%' }} styles={{ cover: { width: '100%' } }} cover={<SkeletonImage active />}>
          <Meta title={<Skeleton.Input active style={{ height: '20px' }} />} />
          <Meta
            style={{ marginTop: '4px', width: '60%' }}
            title={<Skeleton.Input active style={{ height: '20px' }} />}
          />
          <Meta
            style={{ marginTop: '8px', width: '80%' }}
            title={<Skeleton.Input active style={{ height: '20px' }} />}
          />
          <Meta
            style={{ marginLeft: 'auto', marginTop: '10px', width: '75%' }}
            title={<Skeleton.Input active style={{ height: '30px' }} />}
          />
        </Card>
      </div>
    ))}
  </List>
);

const GyomuProductList: FC<IProps> = ({ showButtonViewAll = true, ...props }) => {
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);

  const loggedIn = !!localStorage.getItem('token');
  const {
    noteItemLoading,
    sectionData,
    sectionName,
    addedProducts,
    addingProducts,
    permissions,
    onToggleFavorite,
    onSetProductNote,
    gotoSignIn,
    loadMoreData,
    category,
  } = props;

  const onClickOpenNoteModal = useCallback(
    (product: Product, index: number) => {
      if (loggedIn) {
        setSelectedProduct(product);
        setSelectedIndex(index);
        setIsOpenNoteModal(true);
      } else {
        gotoSignIn();
      }
    },
    [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal, loggedIn],
  );

  const onClickCloseNoteModal = useCallback(() => {
    setSelectedProduct(undefined);
    setSelectedIndex(-1);
    setIsOpenNoteModal(false);
  }, [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal]);

  const onSubmitAddingNote = useCallback(
    (note: string) => {
      if (selectedProduct) {
        onSetProductNote({
          index: selectedIndex,
          section: sectionName,
          note: note,
          id: selectedProduct.id,
        });
      }
      onClickCloseNoteModal();
    },
    [selectedProduct, sectionName, selectedIndex, onClickCloseNoteModal],
  );

  return (
    <Container id={sectionName}>
      <SectionTitleWrapper>
        <SectionTitleBackground src={backgroundSection} />
        <SectionTitleText>{category.nameLocal}</SectionTitleText>
      </SectionTitleWrapper>
      <List>
        {!!sectionData?.data?.length &&
          sectionData?.data?.map((product: Product, index: number) => (
            <Item key={product.id}>
              <MarketItem
                vertialOnly
                listName="Section Product List"
                loggedIn={loggedIn}
                gotoSignIn={gotoSignIn}
                product={product}
                favorite={product.isFavorite}
                note={product.note}
                added={addedProducts[product.id]}
                adding={addingProducts[product.id]}
                position={index}
                permissions={permissions}
                onToggleFavorite={() => onToggleFavorite({ product: product, index: index, section: sectionName })}
                onOpenNote={() => onClickOpenNoteModal(product, index)}
                onDeleteNote={() => {
                  onSetProductNote({ index: index, section: sectionName, note: '', id: product.id });
                  onClickCloseNoteModal();
                }}
                impressionProperties={{ type: TrackingProductImpressionType.CartProduct }}
                addToCartProperties={{ addFrom: TrackingAddToCartAddFromType.CartProduct }}
              />
            </Item>
          ))}
      </List>
      {(sectionData?.loading || !sectionData?.data?.length) && <ProductSkeleton />}
      <ModalNote
        headerLabel={(selectedProduct && selectedProduct.name) || ''}
        isOpen={isOpenNoteModal}
        confirmLoading={noteItemLoading}
        onSubmit={onSubmitAddingNote}
        onClose={onClickCloseNoteModal}
        note={(selectedProduct && selectedProduct.note) || ''}
      />
      {Number(sectionData?.totalPage) > 1 && Number(sectionData?.page) <= Number(sectionData?.totalPage) && (
        <ButtonWrapper>
          <ButtonContainer
            onClick={() =>
              loadMoreData({
                categoryName: sectionName,
                page: sectionData.page,
              })
            }
            loading={sectionData?.loading}
          >
            <FormattedMessage {...messages.seeMore} />
          </ButtonContainer>
        </ButtonWrapper>
      )}
    </Container>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  loading: selectLoading(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
  noteItemLoading: selectNoteItemLoading(),
});
const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onToggleFavorite: (data: IHomeFavoritePayload) => dispatch(setGyomuSuperFavorite(data)),
  onSetProductNote: (data: IHomeProductNotePayload) => dispatch(setGyomuSuperProductNote(data)),
  gotoSignIn: () => dispatch(push('/signin')),
  loadMoreData: (data: any) => dispatch(getMoreSectionData(data)),
});

const withConnect = connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps);

export default withConnect(GyomuProductList);
