import { Button, Spin } from 'antd';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import { selectCategories, selectLang } from 'containers/MainLayout/selectors';
import React, { FC, Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import { ApplicationRootState } from 'types';
import { Category } from 'types/schema';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import media from 'utils/mediaStyle';
import GyomuProductList from './components/GyomuProductList';
import CategoriesSection from './components/CategoriesSection';
import GyomuSuperMessageFooter from './components/GyomuSuperMessageFooter';

import calendarIcon from './images/calendar.svg';
import locationIcon from './images/location.svg';
import reducer from './reducer';
import saga from './saga';
import selectGyomuSuperPage, { selectGyomuCategories } from './selectors';
import { ContainerState } from './types';
import {
  GyomuSuperTopBanner,
  GyomuSuperTopBannerEn,
  GyomuSuperTopBannerMobile,
  GyomuSuperTopBannerMobileEn,
  CommitBanner,
  CommitBannerEn,
  CommitBannerMobile,
  CommitBannerMobileEn,
  GyomuSuperProductsBanner,
  GyomuSuperProductsBannerEn,
  GyomuSuperProductsBannerMobileEn,
  GyomuSuperProductsBannerMobile,
  GyomuSupeStablePricesBanner,
  GyomuSupeStablePricesBannerEn,
  GyomuSupeStablePricesBannerMobile,
  GyomuSupeStablePricesBannerMobileEn,
  JpQualityBanner,
  JpQualityBannerEn,
  JpQualityBannerMobile,
  JpQualityBannerMobileEn,
  GyomuCategoriesBanner,
  GyomuCategoriesBannerEn,
  GyomuCategoriesBannerMobile,
  GyomuCategoriesBannerMobileEn,
} from './images';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Viewport } from 'components/ViewportProvider';
import { LANGUAGES } from 'utils/constants';
import translations from 'translations';
import renderWithWebDomain from 'utils/renderWithWebDomain';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 75pt);
  margin-top: 99.66px;
  padding: 0 16px;
`;

const Spinner = styled(Spin)`
  top: calc(100vh / 2) !important;
  transform: translateY(-50%) !important;
`;

const TopBannerWrapper = styled.div``;

const TopBannerImage = styled.img`
  width: 100%;
`;

const BannerWrapper = styled.div`
  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom};
`}
`;
const BannerImage = styled.img`
  width: 100%;
`;
const EventScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px;
  border-radius: 12px;
  background: #fffbea;
  margin-top: 24px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
  }
`;
const EventTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 991px) {
    border-bottom: 1px solid #9ed9ee;
    margin-bottom: 12px;
    padding-bottom: 12px;
    width: 100%;
    gap: 12px;
  }
`;
const EventTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const EventTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 991px) {
    font-size: 16px;
    font-weight: 600;
  }
`;
const EventTitleBrand = styled.div`
  color: #1f6b83;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 991px) {
    font-size: 16px;
    font-weight: 600;
  }
`;
const EventTitleImage = styled.img`
  width: 80px;
  mix-blend-mode: darken;
  @media (max-width: 991px) {
    width: 40px;
  }
`;
const EventInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 992px) {
    border-left: 1px solid #f8e6a0;
    padding-left: 20px;
  }
`;
const EventInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const EventInfo = styled.div`
  color: #242527;
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 991px) {
    font-size: 14px;
  }
`;
const EventInfoImage = styled(SVGIcon)``;
const EventRegisterButton = styled(Button)`
  padding: 21px 20px;
  color: #f0f9f1;
  font-size: 18px;
  font-weight: 500;
  background: #43a047;
  @media (max-width: 991px) {
    font-size: 16px;
    margin-top: 12px;
    width: 100%;
  }
`;
const FindMoreContainer = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 24px;
  ${media.lg`
    margin-bottom: 48px;
   `};
`;

const ClickHereContainer = styled.a`
  margin-left: 4px;
  color: #43a047;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  cursor: pointer;
  &:hover {
    color: #43a047;
    font-weight: 500;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
  }
`;

export interface IStateProps {
  gyomuSuperPage: ContainerState;
  categories: any;
  gyomuCategories: Category[];
  localLang: string;
}

type Props = IStateProps & RouteComponentProps;

const GyomuSuperPage: FC<Props> = (props) => {
  const {
    gyomuSuperPage: { sections, loading },
    gyomuCategories,
    localLang,
  } = props;
  const [newProducts, ...gyomuCategoriesData] = gyomuCategories;
  const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.En : LANGUAGES.Vi;
  const validUrl = `nguyen-lieu-nhat-ban-gyomu-japan${localLang === 'vi' ? '' : '?lang=en'}`;
  const alternateUrl = `nguyen-lieu-nhat-ban-gyomu-japan${alternateLang === 'vi' ? '' : '?lang=en'}`;
  const renderBanner = (index) => {
    let component = <></>;
    switch (index) {
      case 2:
        component = (
          <Viewport.Consumer>
            {({ width }) => (
              <>
                <BannerWrapper marginBottom={width > 991 ? '48px' : '24px'}>
                  {' '}
                  <BannerImage
                    src={
                      width > 991
                        ? localLang === 'vi'
                          ? JpQualityBanner
                          : JpQualityBannerEn
                        : localLang === 'vi'
                          ? JpQualityBannerMobile
                          : JpQualityBannerMobileEn
                    }
                    loading="lazy"
                  />
                </BannerWrapper>
              </>
            )}
          </Viewport.Consumer>
        );
        break;
      case 4: {
        component = (
          <Viewport.Consumer>
            {({ width }) => (
              <>
                <BannerWrapper marginBottom={width > 991 ? '48px' : '24px'}>
                  {' '}
                  <BannerImage
                    src={
                      width > 991
                        ? localLang === 'vi'
                          ? GyomuCategoriesBanner
                          : GyomuCategoriesBannerEn
                        : localLang === 'vi'
                          ? GyomuCategoriesBannerMobile
                          : GyomuCategoriesBannerMobileEn
                    }
                    loading="lazy"
                  />
                </BannerWrapper>
              </>
            )}
          </Viewport.Consumer>
        );
        break;
      }
      case 6: {
        component = (
          <>
            <Viewport.Consumer>
              {({ width }) => (
                <BannerWrapper marginBottom={width > 991 ? '16px' : '12px'}>
                  {' '}
                  <BannerImage
                    src={
                      width > 991
                        ? localLang === 'vi'
                          ? GyomuSupeStablePricesBanner
                          : GyomuSupeStablePricesBannerEn
                        : localLang === 'vi'
                          ? GyomuSupeStablePricesBannerMobile
                          : GyomuSupeStablePricesBannerMobileEn
                    }
                    loading="lazy"
                  />
                </BannerWrapper>
              )}
            </Viewport.Consumer>
          </>
        );
        break;
      }
      default:
        return;
    }
    return component;
  };
  return (
    <Fragment>
      <Helmet>
        <title>{translations(messages.titleGyomu)}</title>
        <meta property="og:url" content={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="canonical" href={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="alternate" href={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="alternate" hrefLang={localLang} href={renderWithWebDomain(process.env.APP_ENV, validUrl)} />
        <link rel="alternate" hrefLang={alternateLang} href={renderWithWebDomain(process.env.APP_ENV, alternateUrl)} />
        <link rel="alternate" hrefLang="x-default" href={renderWithWebDomain(process.env.APP_ENV, validUrl)} />
        <meta name="description" content={`${translations(messages.descriptionGyomu)}`} />
        <meta property="og:title" content={`${translations(messages.titleGyomu)}`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Spinner spinning={loading}>
        <MainContainer>
          <Viewport.Consumer>
            {({ width }) => (
              <TopBannerWrapper>
                {' '}
                <TopBannerImage
                  src={
                    width > 991
                      ? localLang === 'vi'
                        ? GyomuSuperTopBanner
                        : GyomuSuperTopBannerEn
                      : localLang === 'vi'
                        ? GyomuSuperTopBannerMobile
                        : GyomuSuperTopBannerMobileEn
                  }
                  loading="lazy"
                />
              </TopBannerWrapper>
            )}
          </Viewport.Consumer>
          {/* <EventScheduleWrapper>
            <EventTitleContainer>
              <EventTitleImage src="https://s3-alpha-sig.figma.com/img/c4bf/1608/1e61b1d3a142e1daf26f980417c497a0?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UbS9GVxYlk9aD0Z3DWaI68eHzPMM5QGBqm~cK2ZbpxWks4asBoU-ALdr8AXFgX7qidA9kw1trRQIB1vF6dtkxVgzDZZO04xdzp-x~QiFbW4LA9gStxOaMIHtkMs1zoVvXIMrqNAKu2TgJLEFaDlietacd9BUCEQBFYZHzElmijvYcNHLSJLWOGp2Bzjs9liZuizmgUSWeVaRiTtmdF-0WCPwgUoPm6xyJ1cwV7u3Rk7hLswzQRbtnTgGrgb1uRfjTqnh5OxZ2ta3pJlqIBEsXLV81ZHHpO6KI3y0LKxnx6dwJQgonZIS6UEQvWBSpmfxGzWWZ2Kxdb0m1b-tSIr0Rw__" />
              <EventTitleWrapper>
                <EventTitle>
                  <FormattedMessage {...messages.eventName} />
                </EventTitle>
                <EventTitleBrand>Gyomu Japan x Kamereo</EventTitleBrand>
              </EventTitleWrapper>
            </EventTitleContainer>
            <EventInfoWrapper>
              <EventInfoItem>
                <EventInfoImage src={locationIcon} />
                <EventInfo>
                  <FormattedMessage {...messages.eventLocation} />
                </EventInfo>
              </EventInfoItem>
              <EventInfoItem>
                <EventInfoImage src={calendarIcon} />
                <EventInfo>
                  <FormattedMessage {...messages.eventTime} />
                </EventInfo>
              </EventInfoItem>
            </EventInfoWrapper>
            <EventRegisterButton
              type="primary"
              onClick={() =>
                window.open(
                  localLang === 'vi'
                    ? 'https://kamereo.vn/blog/vi/trien-lam-hop-tac-gyomu-japan-kamereo/'
                    : 'https://kamereo.vn/blog/en/gyomu-event/',
                  '_blank',
                )
              }
            >
              <FormattedMessage {...messages.freeAttendance} />
            </EventRegisterButton>
          </EventScheduleWrapper> */}
          <CategoriesSection categories={gyomuCategories} />
          <GyomuProductList
            sectionData={sections[newProducts?.name || '']}
            category={newProducts || {}}
            sectionName={newProducts?.name || ''}
          />
          <Viewport.Consumer>
            {({ width }) => (
              <BannerWrapper marginBottom={width > 991 ? '48px' : '24px'}>
                {' '}
                <BannerImage
                  src={
                    width > 991
                      ? localLang === 'vi'
                        ? CommitBanner
                        : CommitBannerEn
                      : localLang === 'vi'
                        ? CommitBannerMobile
                        : CommitBannerMobileEn
                  }
                  loading="lazy"
                />
              </BannerWrapper>
            )}
          </Viewport.Consumer>
          <Viewport.Consumer>
            {({ width }) => (
              <BannerWrapper marginBottom={width > 991 ? '48px' : '24px'}>
                {' '}
                <BannerImage
                  src={
                    width > 991
                      ? localLang === 'vi'
                        ? GyomuSuperProductsBanner
                        : GyomuSuperProductsBannerEn
                      : localLang === 'vi'
                        ? GyomuSuperProductsBannerMobile
                        : GyomuSuperProductsBannerMobileEn
                  }
                  loading="lazy"
                />
              </BannerWrapper>
            )}
          </Viewport.Consumer>
          {gyomuCategoriesData.map((item, index) => (
            <>
              <GyomuProductList key={index} sectionData={sections[item.name]} category={item} sectionName={item.name} />
              {renderBanner(index + 1)}
            </>
          ))}
          <GyomuSuperMessageFooter />
        </MainContainer>
      </Spinner>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  gyomuSuperPage: selectGyomuSuperPage(),
  categories: selectCategories(),
  gyomuCategories: selectGyomuCategories(),
  localLang: selectLang(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({
  key: 'gyomuSuperPage',
  reducer: reducer,
});

const withSaga = injectSaga({ key: 'gyomuSuperPage', saga: saga });

export default compose(withConnect, withReducer, withSaga)(GyomuSuperPage);
